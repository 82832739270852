import { BiChevronRight } from "react-icons/bi";
import { NavLink } from "react-router-dom";
export default function ClientFooter() {
    return (
        <footer style={{ backgroundImage: "url(/assets/images/footer.jpg)", backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-3 mb-5 d-flex align-items-center justify-content-center h-100">

                        <NavLink className={"text-center"} to={"/"}>
                            <img src="/assets/images/SSCC_white_crop_no_bg.png" alt="" width={"60%"} />
                        </NavLink>

                    </div>
                    <div className="col-12 col-md-3">
                        <h3 className="footer-title">Departments</h3>
                        <ul className="ps-0">
                            <li>
                                <NavLink to={"/treatments/1"} className="hover-button">
                                    <span className="text">Anti-aging and rejuvenation</span>
                                    <BiChevronRight />
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/treatments/2"} className="hover-button">
                                    <span className="text">Type 1 and type 2 diabetes</span>
                                    <BiChevronRight />
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/treatments/3"} className="hover-button">
                                    <span className="text">Male and female infertility</span>
                                    <BiChevronRight />
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/treatments/4"} className="hover-button">
                                    <span className="text">Sexual weakness</span>
                                    <BiChevronRight />
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/treatments/5"} className="hover-button">
                                    <span className="text">Autism disorders</span>
                                    <BiChevronRight />
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/treatments/6"} className="hover-button">
                                    <span className="text">Joint diseases and osteoporosis</span>
                                    <BiChevronRight />
                                </NavLink>
                            </li>
                        </ul>


                    </div>
                    <div className="col-12 col-md-3">
                        <h3 className="footer-title">Quick links</h3>
                        <ul className="ps-0">
                            <li>
                                <NavLink to={"/"} className="hover-button">
                                    <span className="text">Home</span>
                                    <BiChevronRight />
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/services"} className="hover-button">
                                    <span className="text">Services</span>
                                    <BiChevronRight />
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/treatments"} className="hover-button">
                                    <span className="text">Treatments</span>
                                    <BiChevronRight />
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/doctors"} className="hover-button">
                                    <span className="text">Doctors</span>
                                    <BiChevronRight />
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/stem-cell-treatment"} className="hover-button">
                                    <span className="text">Stem Cell Treatment</span>
                                    <BiChevronRight />
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/contact"} className="hover-button">
                                    <span className="text">Contact</span>
                                    <BiChevronRight />
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    <div className="col-12 col-md-3">
                        <h3 className="footer-title">Location</h3>
                        <p>
                            PO Box 16122 Collins Street West Victoria 8007 Georgia
                        </p>

                        <p><strong>Opening hours: </strong> Always at your service</p>
                    </div>
                </div>
            </div>

            <div>
                <div className="row py-3 border-top">
                    <div className="col-12 col-md-6">
                        <p className="mb-0 text-center">Shefaa Stem Cell Center - 2024</p>

                    </div>
                    <div className="col-12 col-md-6 text-end">
                        <p className="mb-0 text-center">Developed by <a href="https://kreatek.tn" target="_blank" rel="noreferrer" className="navlink"><span className="text">Kreatek.tn</span></a></p>

                    </div>
                </div>
            </div>
        </footer>
    )
}