import { Breadcrumb, Layout } from "antd";
import { Outlet, useLocation } from "react-router-dom";
const { Content } = Layout
export default function ContentAdmin() {

    const location = useLocation();
    // Split the path into segments
    const pathSegments = location.pathname.split('/').filter((segment) => segment);

    // Create breadcrumb items from path segments
    const breadcrumbItems = pathSegments.map((segment, index) => {
        // Create a path to link back to
        const path = `/${pathSegments.slice(0, index + 1).join('/')}`;

        return (
            {
                href: path,
                title: segment.charAt(0).toUpperCase() + segment.slice(1)
            }
        );
    });

    return (
        <Content
            style={{
                margin: '8px 16px 0',
            }}
        >
            {/* route */}
            <Breadcrumb
                style={{
                    margin: 4,
                }}
                items={breadcrumbItems}
            />
            <div
                style={{
                    padding: 8,
                    minHeight: 360,
                    background: "white",
                    borderRadius: 8,
                }}
            >
                <Outlet />
            </div>
        </Content>
    )
}