import React from 'react';
import { BiLogoFacebook, BiLogoInstagram, BiLogoTwitter, BiLogoYoutube } from 'react-icons/bi';

const IdClinic = () => {
    return (
        <div className="partner-page">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <h1 className="mt-3 text-center mb-4">Stem cell treatment in cooperation with IDClinic Group in Ukraine</h1>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <img
                                    src="https://idclinic.com.ua/images/2024/02/19/hall2.jpg"
                                    alt="ID Clinic"
                                    className="img-fluid rounded mb-4"
                                />

                            </div>
                            <div className="col-12 col-md-8">
                                <p className="lead">
                                    ID Clinic is a reputable clinic located in Ukraine, specializing in various medical services including stem cell treatment. We are proud to announce that ID Clinic will be expanding its services to Georgia under the name "Al-Shefaa". This partnership allows us to provide enhanced medical care to our patients.
                                </p>
                            </div>
                        </div>
                        <p className="lead">
                            Visit ID Clinic's website: <a target='_blank' rel="noreferrer" href="https://www.idclinic.com.ua">www.idclinic.com</a>
                        </p>
                        <div className="row mt-2 social-links mb-4 justify-content-center">
                            <div className="col-3 col-md-auto">
                                <a className="btn btn-light btn-social" href="https://www.facebook.com/idclinic.com.ua/" target='_blank' rel='noreferrer'>
                                    <BiLogoFacebook />
                                </a>
                            </div>
                            <div className="col-3 col-md-auto">
                                <a className="btn btn-light btn-social" href="https://x.com/IDclinic2" target='_blank' rel='noreferrer'>
                                    <BiLogoTwitter />
                                </a>
                            </div>
                            <div className="col-3 col-md-auto">
                                <a className="btn btn-light btn-social" href="https://www.instagram.com/i.d.clinic/" target='_blank' rel='noreferrer'>
                                    <BiLogoInstagram />
                                </a>
                            </div>
                            <div className="col-3 col-md-auto">
                                <a className="btn btn-light btn-social" href="https://www.youtube.com/@i.d.clinic9767" target='_blank' rel='noreferrer'>
                                    <BiLogoYoutube />
                                </a>
                            </div>
                        </div>
                        <h2 className="text-center mb-4">Cooperation with ID Clinic Group</h2>
                        <p className="lead">
                            We are excited to announce our cooperation with the ID Clinic Group, a leader in the medical field known for their innovative treatments and commitment to patient care. This collaboration aims to bring cutting-edge medical services and treatments to a broader audience, enhancing the quality of healthcare available in the region. Together with ID Clinic Group, we strive to deliver exceptional medical services, utilizing the latest advancements in medical technology and practices.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IdClinic;
