const stemCells = [
    "Embryonic stem cells",
    "Adult stem cells",
    "Blood stem cells of the peripheral",
    "Blood stem cells , umbilical cord",
    "Stimulating stem cells",
]
export default function StemCellTreatment() {
    return (
        <div>
            <div className="mb-5" style={{ height: 180, backgroundImage: `url(/assets/images/stem-cell-header.jpg)`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}>
                <div className="container h-100">
                    <div className="d-flex h-100 align-items-center">
                        <div>
                            <h3 className="section-title" style={{ color: "white" }}>Stem Cell Treatment</h3>
                            <small style={{color: "white"}}>{`Home > Stem Cell Treatment`}</small>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <img className="rounded-2" src="/assets/images/stem-cells.jpg" width={"100%"} alt="" />
                    </div>
                    <div className="col-12 col-md-6">
                        <h3 className="section-title" style={{ color: "var(--primary-color)" }}>Stem cells</h3>
                        <h4>What are they?</h4>
                        <p>
                            Stem cells are those cells that have the ability to grow and develop into
                            many different types of specialized cells, the cell is a specialized cell has
                            the ability to form a specific type of tissue, and do not have endogenous
                            capacity in the production of a different type of tissue.
                        </p>


                    </div>
                </div>


                <div className="row mt-5">

                    <div className="col-12">
                       
                        <div>
                            <h1>Stem Cell Treatment in Georgia</h1>
                            <p>
                                Stem cell therapy is a modern medical technique that offers new hope for treating many stubborn diseases. Georgia is a special destination for stem cell treatment, thanks to several advantages, including:
                            </p>
                            <ol>
                                <li>
                                    <strong>Cost-effectiveness:</strong> The cost of stem cell treatment in Georgia is significantly lower compared to many other countries, especially European countries and the United States. Patients can receive high-quality treatment at reasonable prices.
                                </li>
                                <li>
                                    <strong>High quality:</strong> Georgia maintains high standards in stem cell treatment through Ukrainian specialists with extensive experience in this field.
                                </li>
                                <li>
                                    <strong>Accessibility and procedures:</strong> Georgia offers easy entry visas for patients from many Arab countries, facilitating travel and accommodation. It also features quick and streamlined procedures for obtaining stem cell treatment.
                                </li>
                                <li>
                                    <strong>Quality assurance:</strong> The stem cell treatment center in Georgia is subject to strict standards by the Georgian Ministry of Health, ensuring patients receive safe and effective treatment.
                                </li>
                                <li>
                                    <strong>Supportive therapeutic environment:</strong> The stem cell treatment center in Georgia provides a supportive and comfortable therapeutic environment for patients, offering round-the-clock medical care.
                                </li>
                                <li>
                                    <strong>Combining treatment with tourism:</strong> Georgia allows patients to combine treatment with tourism, boasting breathtaking natural landscapes and rich historical and cultural sites.
                                </li>
                                <li>
                                    <strong>Low cost of living:</strong> The cost of living in Georgia is generally low, allowing patients to save more money during their treatment journey.
                                </li>
                                <li>
                                    <strong>Safety:</strong> Georgia is a safe and stable country, with low crime rates.
                                </li>
                                <li>
                                    <strong>Geographical location:</strong> Georgia's unique geographical location in the Caucasus region makes it easily accessible from many Arab and European countries.
                                </li>
                            </ol>
                            <p>
                                In conclusion, Georgia offers a unique blend of advantages that make it an ideal destination for treatment, providing patients with the opportunity to receive high-quality treatment at reasonable prices in a safe and comfortable environment.
                            </p>
                        </div>
                    </div>
                    

                </div>
            </div>
        </div>
    )
}