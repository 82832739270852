import { ConfigProvider } from "antd";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import ModuleAdmin from "./admin";
import LoginAdmin from "./admin/pages/Login";
import { adminStore } from "./admin/store/admin.store";
import Client from "./client";
import ClientContent from "./client/layout/Content";
import ContactSinglePage from "./client/pages/single/ContactSinglePage";
import Doctors from "./client/pages/single/Doctors";
import Services from "./client/pages/single/Services";
import StemCellTreatment from "./client/pages/single/StemCellTreatment";
import Treatments from "./client/pages/single/Treatments";
import { PrivateRoute } from "./components/PrivateRoute";
import IdClinic from "./client/pages/single/IdClinic";
import ComingSoon from "./client/pages/single/coming-soon";
AOS.init();
function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // return <ComingSoon/>
  if (window.location.origin === "https://s-stem-cell.com") {
    return <ComingSoon />;
  }
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#35acaa",
        },
      }}
    >
      {/* <Client/> */}
      <Routes>
        <Route path="/" element={<Client />}>
          <Route path="/" element={<ClientContent />} />
          <Route path="/stem-cell-treatment" element={<StemCellTreatment />} />
          <Route path="/treatments/:id?" element={<Treatments />} />
          <Route path="/services" element={<Services />} />
          <Route path="/doctors/:id?" element={<Doctors />} />
          <Route path="/contact" element={<ContactSinglePage />} />
          <Route path="/id-clinic" element={<IdClinic />} />
        </Route>
      </Routes>

      <Provider store={adminStore}>
        <Routes>
          <Route path="/admin" element={<PrivateRoute module={"admin"} />}>
            <Route element={<ModuleAdmin />} path="/admin" exact></Route>
          </Route>
          <Route path="/admin/login" element={<LoginAdmin />} />
        </Routes>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
