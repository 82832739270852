import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Loading from "./components/Loading";
import CacheBuster from "react-cache-buster";

const root = ReactDOM.createRoot(document.getElementById("root"));
const isProduction = process.env.NODE_ENV === "production";
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <CacheBuster
        currentVersion={"3"}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
      >
        <App />
      </CacheBuster>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
