import { BiChevronRight } from "react-icons/bi";
import { SiWpexplorer } from "react-icons/si";
import { NavLink, useNavigate } from "react-router-dom";
export default function ClientHero() {
    const navigate = useNavigate()
    return (
        <div className="hero-container">
            <div className="hero">
                <img data-aos="fade-in" data-aos-delay="300" width={"100%"} src="/assets/images/hero.jpg" alt="" />
                <div className="hero-text">
                    <h1 data-aos="slide-right">
                        Welcome to <br />
                        AL-SHEFAA CLINIC

                    </h1>
                    <h3 data-aos="slide-right">
                        Advantages of Stem Cell Therapy in Georgia with Ukrainian Technology and Specialists
                    </h3>
                    <button onClick={() => navigate("stem-cell-treatment")} className="hover-button mt-3" data-aos="slide-up">
                        <span className="text">
                            Learn more
                        </span>
                        <SiWpexplorer size={30} />
                    </button>
                </div>
            </div>

            <div className="bg-light">
                <div className="container hero-divs">
                    <div className="row g-0">
                        <div style={{ backgroundImage: "url(/assets/images/hero-location.jpg)" }} className="col-12 col-md-4 border-end">
                            <div className="p-5">
                                <h4>Map and directions</h4>
                                <p>
                                    Our comprehensive maps and clear directions ensure that you can find us without any hassle.
                                </p>
                                <a target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/Rarr3zHhgWikjxFn7" className="nav-link hover-button">
                                    <span className="text">
                                        Get directions
                                    </span>
                                    <BiChevronRight />
                                </a>
                            </div>
                        </div>
                        <div style={{ backgroundImage: "url(/assets/images/hero-doctors.jpg)" }} className="col-12 col-md-4 border-end">
                            <div className="p-5">
                                <h4>Meet Our Clinicians</h4>
                                <p>
                                    Our team of dedicated and experienced doctors is at the heart of our practice to provide you with the best care possible.
                                </p>

                                <NavLink to={"/doctors"} className="hover-button nav-link">
                                    <span className="text">
                                        Our Doctors
                                    </span>
                                    <BiChevronRight />
                                </NavLink>
                            </div>
                        </div>
                        <div style={{ backgroundImage: "url(/assets/images/hero-appointments.jpg)" }} className="col-12 col-md-4">
                            <div className="p-5">
                                <h4>Stem cell treatment</h4>
                                <p>
                                    Discover how our advanced stem cell treatments can enhance your quality of life and pave the way to a healthier future.
                                </p>

                                <NavLink to={"/stem-cell-treatment"} className="hover-button nav-link">
                                    <span className="text">
                                        View More
                                    </span>
                                    <BiChevronRight />
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}