import { Layout, Menu } from "antd";
import { FcComboChart } from "react-icons/fc";
const { Sider } = Layout
export default function SideMenuAdmin() {
    const items = [
        {
            key: "1",
            icon: <FcComboChart />,
            label: "Dashboard",
        },

    ]
    return (
        <Sider
            width={240}
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
                console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
                console.log(collapsed, type);
            }}
        >
            <div className="bg-light m-2 mt-3 rounded">
                <img src="/logo.png" alt="" width={"100%"} />
            </div>
            <div className="demo-logo-vertical" />
            <Menu theme="dark" mode="inline" items={items} />
        </Sider>
    )
}