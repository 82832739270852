import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    },
  },
});

export const adminActions = adminSlice.actions;
export const adminSelector = (state) => state.admin;
export default adminSlice.reducer;
