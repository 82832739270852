const doctors = [
  {
    name: "DR. ISLAM DABABSEH",
    title: "Founder, Chief of I.D.Clinic Group",
    image: "https://idclinic.com.ua/images/employees/dr-islam-dababsekh.jpg",
    sectionImage:
      "https://idclinic.com.ua/images/employees/operations/islam_atwork_wm.jpg",
    description:
      "Chief physician of the clinic, candidate of medical sciences, vascular surgeon, general surgeon, ultrasound diagnostician, corresponding member of the International Academy of Education and Science of Ukraine. Graduated from the National Medical University named after O. O. Bogomolets, majoring in 'medical affairs'. He defended his candidate's thesis on the topic 'Chronic critical ischemia of the lower extremities. Clinical and experimental research'. The author of three patents for innovative inventions, has more than 80 published works in Ukrainian and foreign scientific publications. Participant of international conferences. He has experience working as a vascular surgeon abroad - Dubai Trauma Center, UAE (2007-2010).",
    workAreas: [
      "Diagnosis, treatment and prevention of progressive vascular diseases;",
      "treatment of thrombophlebitis, thrombosis, trophic ulcers;",
      "sclerotherapy for varicose veins;",
      "treatment of hemorrhoids;",
      "chronic ischemia of the lower extremities.",
    ],
  },
  {
    name: "HAITHAM DABABSEH",
    title: "Physiotherapist, rehabilitator, massage therapist",
    image: "https://idclinic.com.ua/images/employees/haytham_dababsekh.jpg",
    sectionImage:
      "https://idclinic.com.ua/images/employees/operations/manual_terapy.jpg",
    description:
      "Graduated from the National University of Physical Education and Sports of Ukraine, majoring in physical rehabilitation.Master's degree in physical therapy, occupational therapy. Master of sports in water sports.",
    workAreas: [
      "Physical therapy",
      "rehabilitation",
      "manual therapy",
      "posture correction",
      "manual massage (classic, therapeutic, sports, vacuum, lymphatic drainage, anti-cellulite)",
      "Hijama (Singaporean jar therapy)",
      "ultrasound therapy (BTL-4000 premium)",
      "electrotherapy (BTL-4000 premium)",
      "vacuum therapy",
      "shock wave therapy (BTL 600 SWT)",
      "kinesiotaping",
      "pressotherapy (BTL-6000 Lymphastim Easy)",
      "PRP in orthopedics (plasma lifting)",
      "machine massage (Icoone laser)",
    ],
  },
  {
    name: "ANASTASIA YAKUSHINA",
    title:
      "Obstetrician-gynecologist, reproductive specialist, doctor of ultrasound diagnostics",
    image: "https://idclinic.com.ua/images/employees/anastasia_yakushina.jpg",
    sectionImage:
      "https://idclinic.com.ua/images/employees/operations/ultrasound_diag_wm.jpg",
    description: `She graduated from the National Medical University. O.O. Bogomolets, specialty "medical business".

    She completed an internship in obstetrics and gynecology. He specializes in ultrasound diagnostics, hysteroscopy and laparoscopy, public management and administration.
    
    Participates in medical forums and gives lectures related to gynecology and reproduction.
    
    Works in his profession since 2018.`,
    workAreas: [
      "infertility treatment",
      "induction of ovulation",
      "intrauterine insemination",
      "stimulation of oocyte donors",
      "IVF",
      "endometrial preparation programs in SM programs",
      "fertility preservation programs",
      "pre-pregnancy preparation of women for pregnancy",
      "checking the patency of fallopian tubes before VMI and IO programs",
      "diagnosis and treatment of sexually transmitted diseases",
      "treatment of cervical diseases",
      "diagnosis",
      "colposcopic screening",
      "prevention and treatment",
      "treatment of climacteric disorders",
      "pregnancy management",
      "Ultrasound of pelvic organs",
      "treatment and rehabilitation of gynecological patients",
      "laser procedures for vaginal rejuvenation",
      "stress incontinence",
      "PRP rejuvenation",
      "intimate aesthetics",
    ],
  },
  {
    name: "OKSANA DABABSEH",
    title:
      "Obstetrician-gynecologist, reproductive specialist, doctor of ultrasound diagnostics",
    image: "https://idclinic.com.ua/images/employees/oksana_dababsekh.jpg",
    sectionImage:
      "https://idclinic.com.ua/images/employees/operations/oksana_dababsekh.jpg",
    description: `Has experience working abroad (United Arab Emirates, Israel).
    He is a member of the Ukrainian Association of Reproductive Medicine.
    She graduated from the National Medical University. O.O. Bogomolets, specialty "Medical business".
    She specialized in obstetrics and gynecology at the Department of NMU named after O.O. Bogomolets.
    He specializes in ultrasound diagnostics and pediatrics.
    He has been working in his profession since 2003, has the first qualification category.`,
    workAreas: [
      "infertility treatment",
      "diagnosis and treatment of sexually transmitted diseases",
      "treatment of cervical diseases",
      "colposcopy",
      "treatment of climacteric disorders",
      "pregnancy management",
      "knows the method of vaginal laser rejuvenation",
      "stress incontinence",
    ],
  },
  {
    name: "TOGRUL TEYMUROV",
    title: "Doctor urologist",
    image: "https://idclinic.com.ua/images/employees/togrul_teymurov.jpg",
    sectionImage:
      "https://idclinic.com.ua/images/employees/operations/male_infertility_wm.jpg",
    description: `Graduated from the Ukrainian Medical Lyceum of NMU named after O.O. Bogomolets.
    PVNZ "Kyiv Medical University, majoring in medical affairs".
    National Medical Academy of Postgraduate Education named after P. L. Shupyk, majoring in "Urology"
    Working in the specialty since 2019`,
    workAreas: [
      "Ultrasound diagnosis of the genitourinary system",
      "treatment of urolithiasis",
      "therapy of inflammatory processes of the genitourinary system",
      "treatment of prostatic hyperplasia",
      "diagnosis and treatment of neurogenic diseases of the urinary bladder",
      "operations on the genitals",
      "endoscopic operations for prostate adenoma and urolithiasis",
      "examination and treatment of men in the absence of pregnancy in a married couple",
    ],
  },
  {
    name: "YURIY LESNYAK",
    title: "Head of the Department of Embryology",
    image: "https://idclinic.com.ua/images/employees/yuriy_lesniak.jpg",
    sectionImage:
      "https://idclinic.com.ua/images/employees/operations/yury_lesniak.jpg",
    description: `Graduated with honors from I. Frank Lviv National University, Faculty of Biology. Obtained a specialization in assisted reproductive technologies in the treatment of infertility on the basis of the Shupyk State Polytechnic Institute.

    Received the state award "For many years of conscientious work in the field of health care, significant personal contribution to ensuring a high level of medical care for the population" .
    
    Author of 4 patents in the field of "In Vitro Fertilization", including: 2 invention patents and 2 utility model patents.
    
    Worked as a leading embryologist in the USA at database of the listed institutions:
        * "FERNY - Fertility New York",
        * "The Sperm and Embryo Bank of New Jersey";
        * "Manhattan Reproductive Medicine";
        * "Offices for Fertility and Reproductive Medicine"/"Optimum Egg Bank";
        * "Neway Fertility";
        * "The Fertility Institutes".

Member of the State Delegation of International Scientific Cooperation in the Field of Genetic Engineering and Biotechnology (Guangzhou/Shanghai, China).
Member of the Ukrainian Association of Reproductive Medicine (UARM).
Member of the European Society of Human Reproduction and Embryology (ESHRE) )
Member of the American Society for Reproductive Medicine (ASRM).

Author of more than 10 scientific works published in scientific publications.
    `,
    workAreas: [""],
  },
  {
    name: "SOFIA SELEZNEVA",
    title:
      "Doctor-neurologist, candidate of Medical sciences, associate Professor",
    image: "https://idclinic.com.ua/images/employees/sofia_seleznyova.jpg",
    sectionImage:
      "https://idclinic.com.ua/images/employees/operations/neurology.jpg",
    description: `
    Doctor of the highest category, neurologist, candidate of medical sciences

    Spent an internship at the Department of Nervous Diseases with a neurosurgery course on the basis of the 1st Neurological Department of the OKL named after Kalinin, Donetsk.

    Worked as an assistant at the Department of Nervous Diseases and Medical Genetics with the Neurosurgery Course of DonNMU since 1990. In 2004, she was elected to the position of associate professor of this department.

    In 2000, she received the title and diploma of candidate of medical sciences.

    In 2006, he was awarded the academic title of associate professor

    Seleznyova S.V. the author of more than 190 scientific works, and is also a co-author of a patent for an invention.

    In 2019, she completed an internship abroad (Spain).

    Latest thematic courses in 2021 TU cycle "Modern view of neurotic, stress-related disorders and PTSD" (DNMU).

    Total experience in the specialty "neurology" - 34 years, pedagogical - 32 years.
    `,
    workAreas: [
      "cerebrovascular pathology, including impaired cerebral circulation",
      "vertebrogenic lesions of the spine",
      "polyneuropathy",
      "demyelinating diseases (multiple sclerosis)",
      "parkinsonian syndrome",
      "epilepsy and epileptic syndromes",
      "migraine",
      "anxiety disorders",
      "sleep disturbance",
      "hereditary diseases and others",
    ],
  },
  {
    name: "OLEKSANDRA ILYENKO",
    title: "Cosmetologist-esthetician",
    image: "https://idclinic.com.ua/images/employees/alexandra_ilienko.jpg",
    sectionImage:
      "https://idclinic.com.ua/images/employees/operations/cosmetolor-estetist_wm.jpg",
    description: `She graduated from the National Medical University. O.O. Bogomolets, specialty "medical business".
    He is undergoing an internship in dermatovenerology.`,
    workAreas: [
      "Aesthetic cosmetology (facial massage, cleaning, peeling)",
      "botulinum therapy (Botox, Disport)",
      "mesotherapy",
      "biorevitalization",
      "bioparation",
      "PRP therapy",
      "RF-lifting (BTL EXILIS ELITE)",
      "pressotherapy (BTL-6000 Lymphastim)",
      "IPL photoepilation (BTL EXILITE)",
      "IPL photorejuvenation (BTL EXILITE)",
      "phototherapy of acne IPL (BTL EXILITE)",
      "photoremoval of pigmentation IPL (BTL EXILITE)",
      "treatment of vessels, rosacea IPL (BTL EXILITE)",
      "laser grinding CO2 laser (Finexel Evero)",
      "removal of neoplasms (papillomas)",
    ],
  },
  {
    name: "VOLODYMYR SULYK",
    title:
      "Phlebologist, vascular surgeon, plastic surgeon, Doctor of Medical sciences, Doctor of the highest category",
    image: "https://idclinic.com.ua/images/employees/volodymyr_sulik.jpg",
    sectionImage:
      "https://idclinic.com.ua/images/employees/operations/flebologiya.jpg",
    description: `Graduated from the National Medical University named after O.O. Bogomolets, majoring in "medical affairs"
    Internship and master's degree in majoring in "Surgery"
    
    He defended his candidate's thesis on the topic "Diagnosis and surgical treatment of patients with vertebral artery syndrome and extrazonal compression" at the Institute of Cardio- Vascular Surgery named after Amosov.
    
    Passed the course "Plastic and reconstructive surgery", thematic improvement courses "Contour plastic", "Injection techniques-botulinum therapy".
    
    Graduated from NMU with a specialization in Cardiovascular Surgery and completed the courses "Echocardiography of the Heart and Main Vessels".
    
    Interned in Istanbul in the direction of plastic surgery - rhinoplasty and hair transplantation using the FUE sapphire method.
    
    Associate professor of the department of Surgery No. 4 of O.O. National Medical University Bogomolets.
    
    Associate Professor of the Department of Plastic and Reconstructive Surgery of O.O. Bogomolets.
    
    Member of the Association of Vascular Surgeons of Ukraine.
    
    Member of the European Association of Cardiovascular Surgeons.
    
    Member of the Kyiv Plastic Surgeons Club.
    Works in his specialty since 2006`,
    workAreas: [
      "Laser ablation of telangiectasias and reticular veins",
      "liquid and foam sclerotherapy",
      "miniphlebectomy, phlebectomy, laser phlebectomy",
      "treatment of obliterating atherosclerosis of the arteries of the lower extremities, endarteritis (Diez operation)",
      "plastic surgery",
      "hernioplasty",
      "mammoplasty",
      "gynecomastia",
      "liposuction",
      "lipofilling",
      "otoplasty",
      "blepharoplasty",
      "abdominoplasty",
      "facelift",
      "rhinoplasty",
    ],
  },
  {
    name: "ROMAN FRIEDEL",
    title:
      "Surgeon, candidate of Medical sciences, Oncologist of the highest category, clinical Oncologist",
    image: "https://idclinic.com.ua/images/employees/roman_fridel.jpg",
    sectionImage:
      "https://idclinic.com.ua/images/employees/operations/1day_surgery_wm.jpg",
    description: `Graduated from Ternopil National Medical University named after I.Ya. Gorbachevskiy, specialty "medical affairs"

    He completed an internship in the specialty "surgery" at the Ternopil National Medical University and Ternopil Emergency Hospital
    
    Finished post-graduate studies in the specialty "oncology" at the National Cancer Institute. Defense of a candidate's thesis on the topic: "Neoadjuvant chemoradiotherapy in patients with cancer of the intrathoracic esophagus"
    
    Candidate of medical sciences, author of 32 scientific papers in Ukrainian and international publications
    
    Higher category of oncology of the Ministry of Health of Ukraine.
    
    Availability of scientific and practical publications
    
    Participation in conferences, seminars, symposiums
    
    He is a member of the International Association of Gastroenterological Surgeons and Oncologists (IASGO)`,
    workAreas: [
      {
        title: "General surgery:",
        rows: [
          "removal of various benign formations of the skin and soft tissues (fibroma, lipoma, hygroma, hemangioma, atheroma, etc.)",
          "treatment of an ingrown nail",
          "treatment of wounds, burns",
          "treatment in case of local purulent lesions of the skin (abscess, furuncle, carbuncle, hidradenitis)",
          "stitching",
          "removal of postoperative sutures",
          "removal of soft tissue foreign bodies",
        ],
      },
      {
        title: "Clinical oncology",
        rows: [
          "operative interventions on organs of the abdominal cavity, chest, resection of the stomach",
          "gastrectomy",
          "resection of the insurance duct",
          "resection of the small and large intestine",
          "splenectomy",
          "cholecystectomy",
          "pancreas, liver resections",
          "lung resections",
          "biopsies of lymph nodes and tumors",
          "thoracoscopy",
          "laparoscopy",
          "operations for benign tumors of the esophagus, stomach, and small intestine",
          "esophageal diverticula",
          "hernias of the esophageal opening of the diaphragm",
          "achalasia of the cardia",
          "stenting of the trachea, esophagus, stomach, duodenum, rectum and colon",
          "puncture gastrostomy",
          "treatment of purulent-septic pathology",
          "combat polytraumas",
          "fiery peritonitis",
          "pleural empyemas",
          "abscesses of the abdominal cavity",
          "purulent processes of soft tissues",
          "VAC therapy, including abdominal",
          "palliative neoadjuvant, adjuvant chemotherapy of solid tumors",
        ],
      },
    ],
  },
  {
    name: "DARIA KRYVENKO",
    title: "Dermatologist, cosmetologist-esthetician",
    image: "https://idclinic.com.ua/images/employees/daria_kryvenko.jpg",
    sectionImage:
      "https://idclinic.com.ua/images/employees/operations/dermatolog.jpg",
    description: `
    Graduated from Kharkiv National University named after V.N. Karazin, specialty "Medical business".

    Graduated from the Kharkiv Medical Academy of postgraduate education, majoring in "Dermatovenereologist".

    Graduated from the professional college of the National Pharmaceutical University with a specialty in "pharmacy".
    `,
    workAreas: [
      "Dermatology",
      "aesthetic cosmetology (facial massage, cleaning, peeling)",
      "contour plastic surgery",
      "botulinum therapy (Botox, Disport)",
      "microcurrent therapy",
      "mesotherapy",
      "biorevitalization",
      "bioparation",
      "PRP therapy",
      "RF-lifting (BTL EXILIS ELITE)",
      "pressotherapy (BTL-6000 Lymphastim)",
      "IPL photoepilation (BTLEXILITE)",
      "IPL photorejuvenation (BTLEXILITE)",
      "phototherapy of acne IPL (BTLEXILITE)",
      "photoremoval of pigmentation IPL (BTLEXILITE)",
      "treatment of vessels, rosacea IPL (BTLEXILITE)",
      "laser grinding CO2 laser Finexel Evero",
      "removal of neoplasms",
    ],
  },
  {
    name: "DARINA KOROL",
    title: "Endocrinologist",
    image: "https://idclinic.com.ua/images/employees/darina_korol.jpg",
    sectionImage:
      "https://idclinic.com.ua/images/employees/operations/endocrinology_wm.jpg",
    description: `Graduated from O.O. Bogomolets National Medical University, majoring in "medical affairs"

    Graduated from the National Medical Academy of Postgraduate Education named after P. L. Shupyk, specialization "Endocrinology", Institute of Endocrinology and Metabolism named after V. P. Komisarenka National Academy of Sciences of Ukraine
    
    Passed the National Medical Academy of Postgraduate Education named after P. L. Shupyka, Department of "Internal Diseases", Endocrinology Center.`,
    workAreas: [
      "Disease of the thyroid gland (hypothyroidism, thyrotoxicosis, autoimmune thyroiditis)",
      "parathyroid gland disease (hyperparathyroidism)",
      "diabetes (treatment, diagnosis and prevention)",
      "obesity",
      "management of pregnant women with endocrine diseases",
      "diseases of the adrenal glands",
    ],
  },
  {
    name: "ANNA SUPRUN",
    title: "Pediatrician",
    image: "https://idclinic.com.ua/images/employees/anna_suprun.jpg",
    sectionImage:
      "https://idclinic.com.ua/images/employees/operations/pediatrics.jpg",
    description: `She graduated from the National Medical University. O.O. Bogomolets, specialty "Pediatrics".

    She completed an internship in pediatrics.
    
    The first GV-friendly course for doctors on feeding young children, breastfeeding counseling.
    
    Takes part in medical seminars, conferences, congresses`,
    workAreas: [
      "Patronage of newborns",
      "scheduled and preventive examinations of children",
      "consultation of parents regarding the care of the child, its development, introduction of complementary foods",
      "diagnosis, treatment and prevention of childhood diseases according to the principles of evidence-based medicine",
      "vaccination of children according to the preventive vaccination calendar of the Ministry of Health of Ukraine, as well as an extended vaccination schedule, which includes: prevention of rotavirus infection, pneumococcal, meningococcal infections, chicken pox, influenza, human papilloma virus, hepatitis A",
      "making an individual schedule of vaccinations for children who did not receive vaccinations on time",
      "help with the organization of breastfeeding",
      "treatment and prevention of such conditions as lactostasis, mastitis, nipple cracks in a nursing mother",
    ],
  },
];

export default doctors;
