import React from 'react';
import styles from './ComingSoon.module.css';
import { BiLogoFacebook, BiLogoInstagram, BiLogoTwitter, BiLogoYoutube } from 'react-icons/bi';

function ComingSoon() {
  return (
    <div className={styles.body}>
        <div className={styles.comingSoon}>
      <div className={styles.content}>
        <img src={"/assets/images/Georgia.jpg"} alt="Coming Soon" className={styles.image} />
        <h1 className={styles.title}>I.D.Clinic in its new form in Georgia</h1>
        <p className={styles.text}>We're bringing something exciting to Georgia! Stay tuned for the grand opening of IdClinic's new location. Excellence in healthcare is on its way!</p>
        <div className={styles.socialMedia}>
          <a href="https://www.facebook.com/idclinic.com.ua/" target="_blank" rel="noopener noreferrer" className={`btn btn-light ${styles.socialIcon} ${styles.facebook}`}>
            <BiLogoFacebook />
          </a>
          <a href="https://www.instagram.com/i.d.clinic/" target="_blank" rel="noopener noreferrer" className={`btn btn-light ${styles.socialIcon} ${styles.instagram}`}>
            <BiLogoInstagram />
          </a>
          <a href="https://x.com/IDclinic2" target="_blank" rel="noopener noreferrer" className={`btn btn-light ${styles.socialIcon} ${styles.twitter}`}>
            <BiLogoTwitter/>
          </a>
          <a href="https://www.youtube.com/@i.d.clinic9767" target="_blank" rel="noopener noreferrer" className={`btn btn-light ${styles.socialIcon} ${styles.youtube}`}>
            <BiLogoYoutube />
          </a>
        </div>
        <a href="https://www.idclinic.com.ua" target="_blank" rel="noopener noreferrer" className={`btn btn-light ${styles.clinicLink}`}>
        <img
                                    src="https://idclinic.com.ua/images/base_logo.png"
                                    alt="ID Clinic"
                                    width={80}
                                    className="img-fluid rounded"
                                />
        </a>
      </div>
    </div>
    </div>
  );
}

export default ComingSoon;
