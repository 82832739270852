import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import axios from "axios"
import { API_URL } from '../config/api.config';
import { useDispatch } from 'react-redux';
import { adminActions } from '../admin/store/admin.slice';
export const PrivateRoute = ({ module }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch()
  useEffect(() => {
    checkUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkUser = async () => {
    const accessToken = localStorage.getItem(`accessToken`); // Get access token from local storage

    if (accessToken) {
      const user = await axios
        .get(API_URL + "/auth/check-user", { withCredentials: true, headers: { "Authorization": `Bearer ${accessToken}` } },)
        .then(res => res.data)
        .catch(error => false)
      // User is authenticated

      if (user) {
        dispatch(adminActions.setUser(user));
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false)
      }
    } else {
      setIsAuthenticated(false); // User is not authenticated
    }

    setIsLoading(false);
  }

  if (isLoading) {
    return <div>Loading...</div>; // Display loading indicator while checking auth status
  }

  return isAuthenticated ? <Outlet /> : <Navigate to={`/${module}/login`} />; // Redirect to login if not authenticated
};
