import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import doctors from "../../data/doctors";


const RenderTab = ({ data }) => {
    return (
        <div className="doctor container-fluid">
            <div className="row">
                <div className="col-12 col-md-3">
                    <div className="rounded-3" style={{ overflow: "hidden" }}>
                        <img src={data.image} width={"100%"} alt="" />
                    </div>
                </div>
                <div className="col-12 col-md-9">
                    <h3 style={{ color: "var(--primary-color" }}>{data.name}</h3>
                    <p style={{ fontStyle: "italic", color: "gray" }}><small>{data.title}</small></p>

                    <p>
                        {data.description}
                    </p>
                </div>
            </div>
            {
                data.workAreas.length > 0 ?

                    <div className="row">
                        <div className="col-12">
                            <h2 style={{ color: "var(--primary-color)" }} className="border-bottom mt-2">Main Areas Of Work</h2>
                            {
                                typeof data.workAreas[0] === "object" ?
                                    data.workAreas.map(wa => (
                                        <div key={wa.title}>
                                            <h3>{wa.title}</h3>
                                            <ul>
                                                {
                                                    wa.rows.map(r => (
                                                        <li key={r}>{r}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    ))
                                    :
                                    <ul>
                                        {
                                            data.workAreas.map(wa => (
                                                <li key={wa}>{wa}</li>
                                            ))
                                        }
                                    </ul>
                            }
                        </div>
                    </div>
                    :
                    ""

            }
        </div>
    )
}
export default function Doctors() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [selectedDoctor, setSelectedDoctor] = useState(doctors[0]);
    useEffect(() => {
        if (!id) navigate("/doctors/1")
        else setSelectedDoctor(doctors[id - 1])
    }, [id])

    if (!selectedDoctor) return <> Loading ...</>
    return (
        <>
            <div className="mb-5" style={{ height: 180, backgroundImage: `url(/assets/images/stem-cell-header.jpg)`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}>
                <div className="container h-100">
                    <div className="d-flex h-100 align-items-center">
                        <div>
                            <h3 className="section-title" style={{ color: "white" }}>Our Doctors && Specialists</h3>
                            <small style={{color: "white"}}>{`Home > Doctors && Specialists`}</small>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-3">
                <div className="row">
                    <div className="col-12">
                        <strong>
                            Welcome to Al Shefaa Stem Cell Center, where our team of dedicated healthcare professionals is committed to providing top-quality medical care to our patients. On our website, you can learn more about our esteemed doctors who specialize in various fields such as pediatrics, dermatology, endocrinology, surgery, neurology, and more.
                        </strong>
                    </div>
                </div>
            </div>

            <div className="container py-4">


                <Tabs
                    onTabClick={(index) => {
                        // window.scrollTo({top: 200})
                        navigate("/doctors/" + (index + 1))
                    }}
                    activeKey={id - 1}
                    tabPosition={window.innerWidth < 1000 ? "top" : "left"}
                    items={doctors.map((doctor, index) => {
                        return {
                            label: doctor.name,
                            key: index,
                            children: <RenderTab data={doctor} />
                        }
                    })}
                />
            </div>
        </>
    )
}