import { BiBookReader, BiCategory } from "react-icons/bi";
import { NavLink, useNavigate } from "react-router-dom";

export default function LandingDoctorsDescriptions() {
    const navigate = useNavigate()

    const goToTreatments = (id) => {
        navigate('/treatments/' + id)
    }
    return (
        <section className="landing-doctors-description" style={{ backgroundImage: `url("/assets/images/landing-doctors-description-background.jpg")` }}>
            <div className="container mb-5" style={{ marginTop: 64 }}>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <img src="/assets/images/hero-doctors-description.jpg" alt="" width={"100%"} />
                    </div>

                    <div className="col-12 col-md-6">
                        <h1>The Ukrainian Stem Cell Treatment Technology</h1>
                        <p data-aos="zoom-in"  data-aos-duration="700">
                            Cell therapy is an innovative direction in medicine, based on the application of the regenerative potential of stem cells of an adult organism for the purpose of treating many serious diseases, rehabilitating patients after traumatic injuries, and combating premature signs of aging.
                        </p>
                        <NavLink to={"/stem-cell-treatment"}>
                            <button className="hover-button">
                                <span className="text">Discover more</span>
                                <BiCategory />
                            </button>
                        </NavLink>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <h2 className="text-center">
                    OPD, Medical and <br />
                    Surgical Services We Offer
                </h2>
                <p data-aos="zoom-in"  data-aos-duration="700" className="text-center px-5">
                    Providing Expert Consultations, Advanced Diagnostics, and Personalized Treatments for a Wide Range of Medical and Surgical Conditions
                </p>
                <div className="row mt-5 g-0">
                    <div className="col-12 col-md-6">
                        <img src="/assets/images/ldd-1.jpg" height={"auto"} width={"100%"} alt="" />
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="p-5">
                            <h3>Complete Medical Solutions</h3>
                            <p data-aos="zoom-in"  data-aos-duration="700">
                                At our clinic, we offer a comprehensive array of medical services designed to address all aspects of your health. From preventive care and routine checkups to advanced diagnostics and specialized treatments
                            </p>
                            <p data-aos="zoom-in"  data-aos-duration="700" data-aos-delay="50">
                                Our team of experienced healthcare professionals is dedicated to providing personalized care tailored to your unique needs.
                            </p>
                            <button className="hover-button" style={{ minWidth: 200 }}>
                                <span className="text">Read more</span>
                                <BiBookReader />
                            </button>
                        </div>
                    </div>
                </div>

                <div className="row g-0">
                    <div className="col-12 col-md-6">
                        <div className="row g-0">
                            <div className="col-12 col-md-6">
                                <img style={{objectFit: "cover"}} src="https://www.neutrogena-me.com/sites/neutrogena_menap/files/files/header-images/6_most_effective_anti_aging_ingredients.jpg" alt="" height={300} width={"100%"} />
                            </div>
                            <div className=" col-12 col-md-6 ldd">
                                <div style={{ backgroundColor: "#14a09d", height: 300 }} className="p-2">
                                    <h3>Anti-aging and rejuvenation</h3>
                                    <p data-aos="zoom-in"  data-aos-duration="700">
                                        Stem cell therapy harnesses the regenerative potential of stem cells to combat the effects of aging at the cellular level.
                                    </p>
                                    <button onClick={() => goToTreatments(1)} className="hover-button border">
                                        <span className="text">Read more</span>
                                        <BiBookReader />
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-md-6">
                        <div className="row g-0">
                            <div className="col-12 col-md-6">
                                <img style={{objectFit: "cover"}} src="https://images.ctfassets.net/7oj6q6110np9/1jhE6HDXSLcei2oFsKBS8W/d302f697f3b38c546a4f0a7c0f9388db/diabetic-Clinic.jpg" alt="" height={"300"} width={"100%"} />
                            </div>
                            <div className="ldd col-12 col-md-6">
                                <div className="p-2" style={{ backgroundColor: "#89bbcd", height: 300 }}>
                                    <h3>Type 1 and type 2 diabetes</h3>
                                    <p data-aos="zoom-in"  data-aos-duration="700">
                                        Stem cell therapy works by harnessing the regenerative potential of stem cells to repair damaged pancreatic cells, improve insulin production, and restore normal blood sugar levels.
                                    </p>
                                    <button onClick={() => goToTreatments(2)} className="hover-button border">
                                        <span className="text">Read more</span>
                                        <BiBookReader />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row g-0">

                    <div className="col-12 col-md-6">
                        <div className="p-5">
                            <h3>AUTISM DISORDERS</h3>
                            <p data-aos="zoom-in"  data-aos-duration="700">
                                Autism spectrum disorder (ASD) is a complex neurodevelopmental condition that affects communication, behavior, and social interaction.

                            </p>
                            <p data-aos="zoom-in"  data-aos-duration="700" data-aos-delay="50">
                                Stem cell therapy for autism works by targeting the underlying neurological abnormalities associated with the disorder, such as impaired neural connectivity, inflammation, and oxidative stress.
                            </p>
                            <button onClick={() => goToTreatments(5)} className="hover-button" style={{ minWidth: 200 }}>
                                <span className="text">Read more</span>
                                <BiBookReader />
                            </button>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <img src="https://autismspectrumnews.org/wp-content/uploads/2020/03/AdobeStock_221693488web.jpg" height={"auto"} width={"100%"} alt="" />
                    </div>
                </div>
            </div>
        </section>

    )
}