import Slider from "react-slick"
import doctors from "../data/doctors";
import { BiChevronRight } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const NbSlidesToShow = width =>  {
    if(width < 700) return 1;
    if(width < 1400) return 2;
    return 3
}
const settings = {
    // dots: true,
    
    infinite: true,
    speed: 500,
    slidesToShow: NbSlidesToShow(window.innerWidth),
    slidesToScroll: 1,
    
};
export default function Team() {
    const navigate = useNavigate()
    return (
        <section className="container my-5">
            <h1 className="section-title text-center">
                View Our Doctors
            </h1>
            <p className="section-description text-center">
                Welcome to Al Shefaa Stem Cell Center, where our team of dedicated healthcare professionals is committed to providing top-quality medical care to our patients. On our website, you can learn more about our esteemed doctors who specialize in various fields such as pediatrics, dermatology, endocrinology, surgery, neurology, and more.            </p>
            <Slider {...settings}>
                {
                    doctors.map((doctor, index) => {
                        const short_description = doctor.description.substring(0, 200).trim() + "..."
                        return (
                            <div className="p-3" key={doctor.name}>
                                <div className="doctor-container" style={{ backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundImage: `url(${doctor.image})`, height: 400, display: "flex" }}>
                                    <div className="doctor-details">
                                        <h3>{doctor.title}</h3>
                                        <p>
                                            {short_description}
                                        </p>
                                        <button onClick={() => navigate("/doctors/" + (index + 1))} className="hover-button">
                                            <span className="text">
                                                View More
                                            </span>
                                            <BiChevronRight />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </Slider>
        </section>
    )
}