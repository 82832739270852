import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

export default function ClientMenu() {

    const handleNavLinkClick = () => {
        document.getElementById("navbarNav").classList.remove("show")
    };

    useEffect(() => {
        // Add event listener to handle scroll
        window.addEventListener('scroll', handleScroll);
        return () => {
            // Clean up the event listener on unmount
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const navbar = document.querySelector('.navbar');
        if (navbar) {
            if (window.scrollY > navbar.offsetTop) {
                navbar.classList.add('sticky');
            } else {
                navbar.classList.remove('sticky');
            }
        }
    };
    return (
        <nav className={`navbar navbar-expand-lg`}>
            <div className="container">
                <NavLink to={"/"}>
                    <img className='navbar-brand' src="/assets/images/SSCC_white_crop_no_bg.png" height={45} alt="" />
                </NavLink>
                <NavLink to={"id-clinic"} className='bg-light rounded-2 m-1 p-1'>
                    <img height={40} src="https://idclinic.com.ua/images/base_logo.png" alt="" />
                </NavLink>
                <button style={{ background: "#14a09d" }} className='bg-light navbar-toggler ms-auto border-0' type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse`} id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">

                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link ps-0" aria-current="page" to="/" onClick={handleNavLinkClick}>Home</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={"/services"} onClick={handleNavLinkClick}>Services</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={"nav-link"} to="/treatments" onClick={handleNavLinkClick}>
                                Treatments
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/doctors" onClick={handleNavLinkClick}>Doctors</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={"nav-link"} to="/stem-cell-treatment" onClick={handleNavLinkClick}>
                                Stem cell treatment
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/contact" onClick={handleNavLinkClick}>Contact</NavLink>
                        </li>


                    </ul>
                </div>
            </div>
        </nav>
    );
};

