import { Layout } from "antd";
import ContentAdmin from "./layout/Content";
import FooterAdmin from "./layout/Footer";
import SideMenuAdmin from "./layout/SideMenu";
import TopbarAdmin from "./layout/Topbar";
export default function ModuleAdmin() {
    return (
            <Layout style={{ height: "100vh" }}>
                <SideMenuAdmin />
                <Layout>
                    {/* Top bar */}
                    <TopbarAdmin />
                    {/* Content */}
                    <ContentAdmin />
                    {/* Footer */}
                    <FooterAdmin />
                </Layout>
            </Layout>
    )
}