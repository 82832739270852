import Contact from "../pages/Contact";
import ClientHero from "../pages/Hero";
import LandingDoctorsDescriptions from "../pages/LandingDoctorsDescription";
import Team from "../pages/Team";

export default function ClientContent(){
    return (
        <div>
            <ClientHero/>
            <LandingDoctorsDescriptions/>
            <Team/>
            <Contact/>
            
        </div>
    )
}