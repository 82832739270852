import React, { useState } from 'react';
import { Input, message } from 'antd';
import { BiSend } from 'react-icons/bi';
import axios from 'axios';

export default function ContactSinglePage() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post('http://localhost:3001/send-email', formData);
            message.success('Your message has been sent successfully!');
        } catch (error) {
            console.error('Error sending email:', error);
            message.error('There was an error sending your message. Please try again later.');
        }
    };
    return (
        <>
            <div className="mb-5" style={{ height: 180, backgroundImage: `url(/assets/images/stem-cell-header.jpg)`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}>
                <div className="container h-100">
                    <div className="d-flex h-100 align-items-center">
                        <div>
                            <h3 className="section-title" style={{ color: "white" }}>Contact us</h3>
                            <small style={{color: "white"}}>{`Home > Contact`}</small>
                        </div>
                    </div>
                </div>
            </div>
            <section className="landing-contact" style={{ backgroundImage: "url(/assets/images/landing-contact-background.jpg)" }}>


                <div className="container contact-form" style={{ backgroundImage: "url(/assets/images/contact-form-background.png)" }}>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <img src="/assets/images/doctor-image.png" alt="" height={"auto"} width={"100%"} />
                        </div>
                        <div className="col-12 col-md-7">
                            <div className="pt-5">
                                <h3 className="section-title">
                                    For Queries, Chat with Our Specialist Physicians
                                </h3>
                                <p className="section-description">
                                    On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment.
                                </p>
                            </div>

                            <form onSubmit={handleSubmit}>
                            <div className="row gy-3">
                                <div className="col-12 col-md-6">
                                    <Input
                                        placeholder="Your full name"
                                        size="lg"
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Input
                                        placeholder="Your email"
                                        type="email"
                                        size="lg"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Input
                                        placeholder="Your Phone number"
                                        type="number"
                                        size="lg"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <Input
                                        placeholder="Subject"
                                        type="text"
                                        size="lg"
                                        name="subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-12">
                                    <Input.TextArea
                                        rows={5}
                                        placeholder="Message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-12">
                                    <button className="hover-button" style={{ minWidth: 200 }} type="submit">
                                        <span className="text">Send</span>
                                        <BiSend />
                                    </button>
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>


                <div className="container my-5">
                    <h3 style={{color: "var(--primary-color)"}}>Find us </h3>
                    <div className="rounded-3" style={{ overflow: "hidden" }}>
                        <iframe title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d743.8053832770526!2d44.771876869623526!3d41.780439098203296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40446de9465e7979%3A0x59b3e014528fa963!2s21%20Nodar%20Bokhua%20I%20Ln%2C%20St%200159%2C%20Georgia!5e0!3m2!1sen!2stn!4v1716280594368!5m2!1sen!2stn" width="100%" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                    </div>
                </div>
            </section>
        </>
    )
}