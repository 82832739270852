import { Layout } from "antd";
const { Footer } = Layout
export default function FooterAdmin() {
    return (
        <Footer
            style={{
                textAlign: 'center',
            }}
        >
            NEWLOOK.TN ©{new Date().getFullYear()} Created by KREATEK
        </Footer>
    )
}