import { FaMapMarker, FaPhone } from "react-icons/fa";
import { MdAccessTime } from "react-icons/md";
import { Typography } from "antd";
const { Text } = Typography
export default function ClientTopbar() {
    return (
        <div className="container py-3">
            <div className="row">
                <div className="col-12 col-md-4 text-center border-end">

                    <a href="tel:+448000584493" className="nav-link">
                        <FaPhone color="#14a09d" size={20} />
                        <Text style={{ color: "#14a09d" }} className="ms-2">
                            +44 800 058 44  93
                        </Text>
                    </a>
                </div>
                <div className="col-12 col-md-4 text-center border-end">
                    <div className="d-flex align-items-center h-100 justify-content-center">
                        <FaMapMarker color="#14a09d" size={20} />
                        <a className="nav-link" target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/Rarr3zHhgWikjxFn7">
                            <small style={{ marginLeft: 8, lineHeight: 1.8 }}>
                                Nodar Bokhua St, 21 , Tbilisi , Georgia
                            </small>
                        </a>

                    </div>
                </div>
                <div className="col-12 col-md-4 text-center">
                    <div className="d-flex align-items-center justify-content-center">
                        <MdAccessTime color="#14a09d" size={20} />
                        <small className="ms-2">always at your service</small>
                    </div>
                </div>
            </div>
        </div>
    )
}