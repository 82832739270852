import { Card } from "antd"
import { BiWorld } from "react-icons/bi";
import { FcAutomotive } from "react-icons/fc"
import { GiCook, GiHealing, GiSpaceNeedle } from "react-icons/gi"
import { MdOutlineTranslate } from "react-icons/md";
import { IoMedkitOutline,IoAirplaneOutline } from "react-icons/io5";
import { LuHotel } from "react-icons/lu";

const services = [
    {
        title: "Comfortable Transportation",
       icon: <FcAutomotive/>,
        description: "Whether you're arriving at or departing from the airport, or require transport to and from your hotel or hospital, we provide reliable and comfortable transportation services. Our professional drivers ensure a smooth and hassle-free journey, allowing you to focus on what matters most."
    },
    {
        title: "Visa Assistance",
       icon: <BiWorld/>,
        description: "Navigating the visa application process can be complex and time-consuming. Let us handle it for you. Our experienced team will guide you through each step of the visa application process, ensuring a seamless experience."
    },
    {
        title: "Relaxation Services",
       icon: <GiSpaceNeedle/>,
        description: "Indulge in our relaxation services, including massages and beauty treatments. After a long journey or as part of your recovery process, take time to unwind and rejuvenate with our expertly delivered services."
    },
    {
        title: "Therapeutic Programs",
       icon: <GiHealing/>,
        description: "For those seeking specialized medical treatments, we offer comprehensive therapeutic programs. From diagnosis and consultation to stem cell therapy and medication, we provide holistic care tailored to your specific needs."
    },
    {
        title: "Translation Services",
       icon: <MdOutlineTranslate/>,
        description: "Communication should never be a barrier to receiving the care you deserve. Our professional translators are available to assist you throughout your journey, ensuring clear and effective communication with healthcare providers and staff."
    },
    {
        title: "Post-Treatment Follow-up",
       icon: <IoMedkitOutline/>,
        description: "Your well-being is our priority even after treatment. We offer post-treatment follow-up services to monitor your progress and address any concerns, ensuring optimal recovery and peace of mind."
    },
    {
        title: "Healthy Meals",
       icon: <GiCook/>,
        description: "Enjoy three nutritious meals a day, prepared according to your dietary preferences and requirements. Our culinary team ensures that every meal is not only delicious but also nourishing, providing you with the energy you need for your journey ahead."
    },
    {
        title: "Luxurious Accommodation",
       icon: <LuHotel/>,
        description: "Experience the height of comfort and luxury with a stay at our 5-star hotel. From elegant rooms to world-class amenities, our accommodation provides a sanctuary where you can relax and recharge."
    },
    {
        title: "Airport Reception",
       icon: <IoAirplaneOutline/>,
        description: "Start your journey on the right foot with our airport reception service. Our friendly staff will greet you upon arrival, providing a warm welcome and assisting you with any immediate needs or questions."
    }
];


const RenderService = ({ data }) => {
    return (
        <Card title={data.title} extra={data.icon} style={{ width: "100%", height: 220 }}>
            <p>
                {
                    data.description
                }
            </p>
        </Card>
    )
}
export default function Services() {
    return (
        <>
            <div className="mb-5" style={{ height: 180, backgroundImage: `url(/assets/images/stem-cell-header.jpg)`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}>
                <div className="container h-100">
                    <div className="d-flex h-100 align-items-center">
                        <div>
                            <h3 className="section-title" style={{ color: "white" }}>Our services</h3>
                            <small style={{color: "white"}}>{`Home > Services`}</small>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container py-3">
                <div className="row g-3 services">
                    {
                        services.map(s => (
                            <div key={s.title} className="col-12 col-md-4">
                                <RenderService data={s} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}