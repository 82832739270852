import ClientContent from "./Content";
import ClientFooter from "./Footer";
import ClientMenu from "./Menu";
import ClientTopbar from "./Topbar";
import { Outlet } from "react-router-dom";
export default function ClientLayout() {
    return (
        <>
            <ClientTopbar />
            <ClientMenu />
            <Outlet />
            {/* <ClientContent/> */}
            <ClientFooter />
        </>
    )
}