import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Flex, Layout, Tag, theme } from "antd";
import { adminSelector } from "../store/admin.slice";
import { useSelector } from "react-redux";
const { Header } = Layout;

export default function TopbarAdmin() {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const { user } = useSelector(adminSelector)
    const deconnecter = () => {
        localStorage.removeItem('accessToken')
        window.location.reload()
    }
    return (
        <Header
            style={{
                padding: 8,
                background: colorBgContainer,
                height: "unset"
            }}
        >
            <div className="container-fluid">
                <Flex gap={"4px 8px"} wrap="wrap" justify="space-between" align="center">
                    <Tag icon={<UserOutlined />} color="#55acee">
                        {user.nom} {user.prenom}
                    </Tag>
                    <Button onClick={deconnecter} icon={<LogoutOutlined />}>
                        Se déconnecter
                    </Button>
                </Flex>
            </div>
        </Header>
    )
}