import { Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const content = [
    {
        title: "ANTI-AGING AND REJUVENATION",
        introduction: "In our quest for longevity and vitality, science has paved the way for groundbreaking advancements in anti-aging and rejuvenation therapies. One such innovation is the use of stem cell treatment, offering promising results in slowing down the aging process and restoring youthful vitality.",
        how: "Aging is a complex process characterized by the gradual decline in cellular function and tissue regeneration. Stem cell therapy harnesses the regenerative potential of stem cells to combat the effects of aging at the cellular level. These remarkable cells have the ability to differentiate into various cell types, repair damaged tissues, and stimulate the body's natural healing mechanisms.",
        benefits: [
            {
                title: "Skin Rejuvenation",
                description: "Stem cell therapy promotes collagen production, improves skin elasticity, and reduces the appearance of wrinkles and fine lines, resulting in smoother, firmer, and more youthful-looking skin."
            },
            {
                title: "Hair Regrowth",
                description: "Stem cells have been shown to stimulate hair follicle regeneration, leading to thicker, fuller hair growth and addressing common issues such as hair thinning and baldness."
            },
            {
                title: "Improved Energy and Vitality",
                description: "By revitalizing aging tissues and organs, stem cell treatment can enhance energy levels, boost immune function, and improve overall vitality and quality of life."
            },
            {
                title: "Joint Health",
                description: "Aging often leads to joint degeneration and mobility issues. Stem cell therapy can help repair damaged cartilage, reduce inflammation, and improve joint function, allowing for greater mobility and flexibility."
            }
        ],
        conditionsTreated: {
            title: "Stem cell treatment for anti-aging and rejuvenation can address a range of age-related concerns, including:",
            rows: [
                "Fine lines and wrinkles",
                "Skin sagging and laxity",
                "Hair thinning and baldness",
                "Reduced energy and stamina",
                "Joint pain and stiffness",
                "Cognitive decline",
            ]
        },
        question: {
            title: "Is Stem Cell Treatment Right for You?",
            content: "If you're looking to turn back the clock and reclaim your youthful vitality, stem cell therapy may be an option worth considering. However, it's essential to consult with a qualified healthcare provider to determine if you're a suitable candidate for treatment. Factors such as your overall health, medical history, and treatment goals will be taken into account to develop a personalized treatment plan."
        }
    },
    {
        title: "TYPE 1 AND TYPE 2 DIABETES",
        introduction: "Diabetes, whether Type 1 or Type 2, is a chronic condition that affects millions worldwide. While traditional treatments focus on managing symptoms, stem cell therapy offers a promising alternative by addressing the underlying cause of the disease and promoting tissue regeneration.",
        how: "Stem cell therapy works by harnessing the regenerative potential of stem cells to repair damaged pancreatic cells, improve insulin production, and restore normal blood sugar levels. These specialized cells have the ability to differentiate into insulin-producing beta cells, effectively replacing those lost to the autoimmune attack in Type 1 diabetes or dysfunction in Type 2 diabetes.",
        benefits: [
            {
                title: "Improved Blood Sugar Control",
                description: "By replenishing insulin-producing cells, stem cell therapy can help regulate blood sugar levels, reducing the need for insulin injections and mitigating the risk of diabetic complications."
            },
            {
                title: "Reduced Dependency on Medications",
                description: "Stem cell treatment may allow patients to decrease or eliminate their reliance on diabetes medications, leading to a better quality of life and reduced healthcare costs."
            },
            {
                title: "Prevention of Complications",
                description: "By addressing the root cause of diabetes, stem cell therapy has the potential to prevent or delay the onset of diabetic complications such as neuropathy, nephropathy, and retinopathy."
            },
            {
                title: "Improved Quality of Life",
                description: "With better blood sugar control and reduced reliance on medications, patients can enjoy a higher quality of life, with fewer dietary restrictions and a lower risk of long-term complications."
            }
        ],
        conditionsTreated: {
            title: "Stem cell treatment for diabetes can address various aspects of the disease, including:",
            rows: [
                "Type 1 diabetes",
                "Type 2 diabetes",
                "Insulin resistance",
                "Diabetic neuropathy",
                "Diabetic nephropathy",
                "Diabetic retinopathy"
            ]
        },
        question: {
            title: "Is Stem Cell Treatment Right for You?",
            content: "If you're living with Type 1 or Type 2 diabetes and seeking alternative treatment options, stem cell therapy may offer hope for better management and improved quality of life. However, it's essential to consult with a qualified healthcare provider to determine if you're a suitable candidate for treatment. Factors such as your overall health, disease progression, and treatment goals will be considered when developing a personalized treatment plan."
        }
    },
    {
        title: "MALE AND FEMALE INFERTILITY",
        introduction: "Infertility can be a challenging and emotional journey for couples struggling to conceive. While traditional treatments offer limited success and often come with significant risks and side effects, stem cell therapy presents a promising alternative for addressing the underlying causes of infertility and improving reproductive health.",
        how: "Stem cell therapy for infertility works by harnessing the regenerative potential of stem cells to repair damaged reproductive tissues, improve hormone levels, and enhance fertility. These specialized cells have the ability to differentiate into various cell types found in the reproductive system, promoting tissue regeneration and restoring normal function.",
        benefits: [
            {
                title: "Improved Sperm Quality",
                description: "For men, stem cell therapy can lead to improvements in sperm quality, including increased sperm count, motility, and morphology, resulting in better chances of fertilization and conception."
            },
            {
                title: "Enhanced Ovulation and Egg Quality",
                description: "For women, stem cell treatment may improve ovulation and egg quality, increasing the likelihood of successful fertilization and implantation, and reducing the risk of miscarriage."
            },
            {
                title: "Addressing Underlying Causes",
                description: "Unlike symptomatic treatments that only address the symptoms of infertility, stem cell therapy targets the root cause of the problem, addressing issues such as hormonal imbalances, reproductive tissue damage, and oxidative stress."
            },
            {
                title: "Natural and Safe",
                description: "Stem cell therapy offers a natural and safe approach to treating infertility, without the need for invasive procedures or harsh medications. By utilizing the body's own regenerative capabilities, it minimizes the risk of adverse reactions and complications."
            }
        ],
        conditionsTreated: {
            title: "Stem cell treatment for infertility can address various conditions, including:",
            rows: [
                "Male infertility (low sperm count, poor sperm motility, abnormal sperm morphology)",
                "Female infertility (ovulatory disorders, diminished ovarian reserve, tubal blockages)",
                "Unexplained infertility",
                "Recurrent miscarriages",
                "Age-related infertility"
            ]
        },
        question: {
            title: "Is Stem Cell Treatment Right for You?",
            content: "If you and your partner are struggling with infertility and seeking alternative treatment options, stem cell therapy may offer hope for achieving your dream of starting a family. However, it's essential to consult with a qualified fertility specialist to determine if you're suitable candidates for treatment. Factors such as your overall health, medical history, and fertility goals will be considered when developing a personalized treatment plan."
        }
    }
    ,
    {
        title: "SEXUAL WEAKNESS ",
        introduction: "Sexual weakness, often referred to as erectile dysfunction (ED) or impotence, can significantly impact a person's quality of life and intimate relationships. While traditional treatments such as medications and therapy may provide temporary relief, stem cell therapy offers a promising solution for addressing the underlying causes of sexual weakness and restoring sexual function.",
        how: "Stem cell therapy for sexual weakness works by harnessing the regenerative potential of stem cells to repair damaged blood vessels, nerves, and tissues in the genital area, improving blood flow, nerve function, and sensitivity. These specialized cells have the ability to differentiate into various cell types found in the reproductive system, promoting tissue regeneration and restoring normal sexual function.",
        benefits: [
            {
                title: "Improved Erectile Function",
                description: "Stem cell therapy can lead to improvements in erectile function, including increased firmness, duration, and frequency of erections, resulting in enhanced sexual performance and satisfaction."
            },
            {
                title: "Increased Sensitivity and Pleasure",
                description: "By repairing nerve damage and enhancing blood flow to the genital area, stem cell treatment may increase sensitivity and pleasure during sexual activity, leading to a more fulfilling and enjoyable experience."
            },
            {
                title: "Natural and Non-Invasive",
                description: "Unlike surgical procedures or medications, stem cell therapy offers a natural and non-invasive approach to treating sexual weakness, with minimal discomfort and downtime. By utilizing the body's own regenerative capabilities, it minimizes the risk of adverse reactions and complications."
            },
            {
                title: "Long-lasting Results",
                description: "With proper treatment and follow-up care, the benefits of stem cell therapy for sexual weakness can be long-lasting, providing sustained improvements in erectile function and sexual satisfaction."
            }
        ],
        conditionsTreated: {
            title: "Stem cell treatment for sexual weakness can address various conditions, including:",
            rows: [
                "Erectile dysfunction (ED)",
                "Premature ejaculation",
                "Low libido (decreased sexual desire)",
                "Orgasmic dysfunction",
                "Penile curvature (Peyronie's disease)"
            ]
        },
        question: {
            title: "Is Stem Cell Treatment Right for You?",
            content: "If you're experiencing sexual weakness and seeking a safe and effective treatment option, stem cell therapy may offer hope for restoring your sexual health and confidence. However, it's essential to consult with a qualified healthcare provider specializing in sexual medicine to determine if you're a suitable candidate for treatment. Factors such as your overall health, medical history, and treatment goals will be considered when developing a personalized treatment plan."
        }
    }
    ,
    {
        title: "AUTISM DISORDERS",
        introduction: "Autism spectrum disorder (ASD) is a complex neurodevelopmental condition that affects communication, behavior, and social interaction. While there is no cure for autism, stem cell therapy offers a promising approach to managing symptoms and improving the quality of life for individuals with ASD.",
        how: "Stem cell therapy for autism works by targeting the underlying neurological abnormalities associated with the disorder, such as impaired neural connectivity, inflammation, and oxidative stress. These specialized cells have the ability to migrate to damaged areas of the brain, promote tissue repair, and modulate the immune response, leading to improvements in cognitive function, behavior, and social skills.",
        benefits: [
            {
                title: "Enhanced Cognitive Function",
                description: "Stem cell therapy may lead to improvements in cognitive function, including attention, memory, and language skills, allowing individuals with ASD to better engage with their environment and participate in daily activities."
            },
            {
                title: "Behavioral Improvements",
                description: "By modulating neurotransmitter levels and promoting neural network formation, stem cell treatment can help reduce behavioral symptoms associated with autism, such as repetitive behaviors, hyperactivity, and sensory sensitivities."
            },
            {
                title: "Improved Social Interaction",
                description: "Stem cell therapy has the potential to enhance social skills and communication abilities, enabling individuals with ASD to form more meaningful relationships, participate in social activities, and integrate into their communities."
            },
            {
                title: "Reduced Neuroinflammation",
                description: "By suppressing inflammatory responses and promoting tissue repair, stem cell treatment may help alleviate neuroinflammation and oxidative stress in the brain, mitigating the progression of autism-related symptoms."
            }
        ],
        conditionsTreated: {
            title: "Stem cell treatment for autism disorders can address various symptoms and challenges, including:",
            rows: [
                "Impaired social interaction",
                "Communication difficulties",
                "Repetitive behaviors",
                "Sensory sensitivities",
                "Hyperactivity",
                "Attention deficits"
            ]
        },
        question: {
            title: "Is Stem Cell Treatment Right for You?",
            content: "If you or your loved one is living with autism spectrum disorder and seeking alternative treatment options, stem cell therapy may offer hope for symptom management and improved quality of life. However, it's essential to consult with a qualified healthcare provider specializing in autism treatment to determine if you're a suitable candidate for treatment. Factors such as your overall health, medical history, and treatment goals will be considered when developing a personalized treatment plan."
        }
    }
    ,
    {
        title: "JOINT DISEASES AND OSTEOPOROSIS",
        introduction: "Joint diseases such as osteoarthritis and osteoporosis can significantly impact mobility, independence, and quality of life. While traditional treatments offer symptomatic relief, stem cell therapy presents a promising approach to addressing the underlying causes of these conditions and promoting tissue regeneration.",
        how: "Stem cell therapy for joint diseases and osteoporosis works by harnessing the regenerative potential of stem cells to repair damaged cartilage, bone, and connective tissues, improve joint function, and reduce pain and inflammation. These specialized cells have the ability to differentiate into chondrocytes, osteoblasts, and other cell types involved in bone and cartilage formation, facilitating tissue repair and regeneration.",
        benefits: [
            {
                title: "Joint Repair and Regeneration",
                description: "Stem cell therapy can promote the repair and regeneration of damaged joint tissues, including cartilage, bone, and ligaments, leading to improved joint function, mobility, and quality of life for individuals with osteoarthritis and other joint diseases."
            },
            {
                title: "Pain Reduction",
                description: "By targeting the underlying cause of joint pain and inflammation, stem cell treatment may provide long-lasting pain relief, allowing patients to reduce or eliminate their reliance on pain medications and enjoy a better quality of life."
            },
            {
                title: "Prevention of Bone Loss",
                description: "Stem cell therapy has the potential to stimulate bone formation and inhibit bone resorption, reducing the risk of osteoporosis-related fractures and improving bone density and strength."
            },
            {
                title: "Minimally Invasive",
                description: "Unlike surgical procedures such as joint replacement, stem cell therapy is minimally invasive and typically performed on an outpatient basis, with minimal discomfort and downtime for the patient."
            }
        ],
        conditionsTreated: {
            title: "Stem cell treatment for joint diseases and osteoporosis can address various conditions, including:",
            rows: [
                "Osteoarthritis",
                "Rheumatoid arthritis",
                "Degenerative joint disease",
                "Osteoporosis",
                "Cartilage injuries",
                "Ligament and tendon injuries"
            ]
        },
        question: {
            title: "Is Stem Cell Treatment Right for You?",
            content: "If you're living with joint pain, stiffness, or reduced mobility due to osteoarthritis, osteoporosis, or other joint diseases, stem cell therapy may offer hope for relief and improved quality of life. However, it's essential to consult with a qualified healthcare provider specializing in regenerative medicine to determine if you're a suitable candidate for treatment. Factors such as your overall health, disease severity, and treatment goals will be considered when developing a personalized treatment plan."
        }
    }
    ,
    {
        title: "RETINAL DISEASES AND OPTIC NERVE ATROPHY",
        introduction: "Retinal diseases and optic nerve atrophy can cause vision loss and impairment, significantly impacting quality of life. While traditional treatments offer limited success in preserving vision, stem cell therapy presents a promising approach to restoring vision and improving visual function.",
        how: "Stem cell therapy for retinal diseases and optic nerve atrophy works by harnessing the regenerative potential of stem cells to repair damaged retinal cells, improve nerve function, and promote tissue regeneration in the eye. These specialized cells have the ability to differentiate into retinal pigment epithelial cells, photoreceptors, and other cell types essential for vision, facilitating tissue repair and restoration of visual function.",
        benefits: [
            {
                title: "Vision Restoration",
                description: "Stem cell therapy may lead to improvements in visual acuity, contrast sensitivity, and color vision, allowing individuals with retinal diseases and optic nerve atrophy to regain lost vision and improve their overall quality of life."
            },
            {
                title: "Prevention of Vision Loss",
                description: "By targeting the underlying cause of vision loss and promoting retinal and optic nerve regeneration, stem cell treatment may help prevent further deterioration of vision and preserve remaining visual function."
            },
            {
                title: "Non-Invasive",
                description: "Unlike invasive procedures such as retinal surgery, stem cell therapy is non-invasive and typically performed as an outpatient procedure, with minimal discomfort and downtime for the patient."
            },
            {
                title: "Potential for Long-Term Benefits",
                description: "With ongoing advancements in stem cell research and technology, there is the potential for long-term benefits and sustained improvements in vision following stem cell therapy for retinal diseases and optic nerve atrophy."
            }
        ],
        conditionsTreated: {
            title: "Stem cell treatment for retinal diseases and optic nerve atrophy can address various conditions, including:",
            rows: [
                "Age-related macular degeneration",
                "Retinitis pigmentosa",
                "Glaucoma-related optic nerve damage",
                "Optic nerve atrophy",
                "Diabetic retinopathy",
                "Retinal detachment"
            ]
        },
        question: {
            title: "Is Stem Cell Treatment Right for You?",
            content: "If you're experiencing vision loss or impairment due to retinal diseases or optic nerve atrophy and seeking alternative treatment options, stem cell therapy may offer hope for vision restoration and preservation. However, it's essential to consult with a qualified ophthalmologist specializing in retinal disorders to determine if you're a suitable candidate for treatment. Factors such as your overall eye health, disease progression, and treatment goals will be considered when developing a personalized treatment plan."
        }
    }
    ,
    {
        title: "BLOOD DISEASES",
        introduction: "Blood diseases encompass a wide range of conditions affecting the production and function of blood cells, such as leukemia, lymphoma, and sickle cell anemia. While traditional treatments like chemotherapy and radiation therapy aim to manage symptoms, stem cell therapy offers a promising avenue for treating the root cause of these diseases and restoring healthy blood function.",
        how: "Stem cell therapy for blood diseases involves the transplantation of healthy stem cells into the patient's body, where they can replenish and regenerate damaged or diseased blood cells. These stem cells have the remarkable ability to differentiate into various types of blood cells, including red blood cells, white blood cells, and platelets, thereby restoring normal blood production and function.",
        benefits: [
            {
                title: "Blood Cell Regeneration",
                description: "Stem cell therapy stimulates the regeneration of healthy blood cells, which can help improve blood counts, reduce the risk of infections, and alleviate symptoms associated with blood diseases."
            },
            {
                title: "Reduced Reliance on Transfusions",
                description: "By restoring the body's ability to produce healthy blood cells, stem cell treatment may reduce or eliminate the need for blood transfusions, improving quality of life and reducing the risk of complications."
            },
            {
                title: "Potential for Long-Term Remission",
                description: "In certain cases, stem cell therapy can lead to long-term remission or even cure for blood diseases such as leukemia and lymphoma, offering hope for a brighter future for patients and their families."
            },
            {
                title: "Customized Treatment Approach",
                description: "Stem cell therapy can be tailored to each patient's specific condition and treatment goals, offering a personalized approach to managing blood diseases and optimizing treatment outcomes."
            }
        ],
        conditionsTreated: {
            title: "Stem cell treatment for blood diseases can address various conditions, including:",
            rows: [
                "Leukemia",
                "Lymphoma",
                "Sickle cell anemia",
                "Thalassemia",
                "Myelodysplastic syndromes",
                "Aplastic anemia"
            ]
        },
        question: {
            title: "Is Stem Cell Treatment Right for You?",
            content: "If you're living with a blood disease and seeking alternative treatment options, stem cell therapy may offer hope for improved blood counts, symptom relief, and quality of life. However, it's essential to consult with a qualified hematologist or oncologist specializing in blood disorders to determine if you're a suitable candidate for treatment. Factors such as your overall health, disease stage, and treatment goals will be considered when developing a personalized treatment plan."
        }
    }
    ,
    {
        title: "LIVER DISEASES",
        introduction: "Liver diseases, ranging from fatty liver disease to cirrhosis, pose significant health risks and challenges. While traditional treatments focus on managing symptoms and slowing disease progression, stem cell therapy offers a promising approach to addressing the underlying causes of liver diseases and promoting tissue regeneration.",
        how: "Stem cell therapy for liver diseases involves the transplantation of healthy stem cells into the liver, where they can integrate into the damaged tissue and promote repair and regeneration. These specialized cells have the unique ability to differentiate into hepatocytes, the primary functional cells of the liver, and stimulate the production of growth factors and cytokines that support tissue healing.",
        benefits: [
            {
                title: "Liver Regeneration",
                description: "Stem cell therapy stimulates the regeneration of healthy liver tissue, which can improve liver function, reduce inflammation, and slow the progression of liver diseases such as cirrhosis and fibrosis."
            },
            {
                title: "Reduction of Fibrosis",
                description: "By promoting tissue repair and reducing collagen deposition, stem cell treatment may help reduce liver fibrosis, a common complication of chronic liver diseases that can lead to impaired liver function and liver failure."
            },
            {
                title: "Improvement in Symptoms",
                description: "Stem cell therapy can lead to improvements in symptoms associated with liver diseases, such as fatigue, jaundice, abdominal swelling, and impaired cognitive function, resulting in a better quality of life for patients."
            },
            {
                title: "Potential for Long-Term Benefits",
                description: "With ongoing advancements in stem cell research and technology, there is the potential for long-term benefits and sustained improvements in liver function and overall health following stem cell therapy for liver diseases."
            }
        ],
        conditionsTreated: {
            title: "Stem cell treatment for liver diseases can address various conditions, including:",
            rows: [
                "Non-alcoholic fatty liver disease (NAFLD)",
                "Alcoholic liver disease",
                "Hepatitis (viral hepatitis, autoimmune hepatitis)",
                "Cirrhosis",
                "Liver fibrosis",
                "Liver failure"
            ]
        },
        question: {
            title: "Is Stem Cell Treatment Right for You?",
            content: "If you're living with a liver disease and seeking alternative treatment options, stem cell therapy may offer hope for improved liver function, symptom relief, and quality of life. However, it's essential to consult with a qualified hepatologist or gastroenterologist specializing in liver diseases to determine if you're a suitable candidate for treatment. Factors such as your overall health, liver function, and treatment goals will be considered when developing a personalized treatment plan."
        }
    }
    ,
    {
        title: "INTESTINAL DISEASES",
        introduction: "Intestinal diseases, such as Crohn's disease and ulcerative colitis, can have a profound impact on quality of life and overall health. While traditional treatments aim to manage symptoms and reduce inflammation, stem cell therapy offers a promising approach to addressing the underlying causes of intestinal diseases and promoting tissue repair.",
        how: "Stem cell therapy for intestinal diseases involves the transplantation of healthy stem cells into the intestine, where they can integrate into the damaged tissue and promote repair and regeneration. These specialized cells have the unique ability to differentiate into various cell types found in the intestine, including epithelial cells, goblet cells, and enterocytes, and stimulate the production of growth factors and cytokines that support tissue healing.",
        benefits: [
            {
                title: "Intestinal Regeneration",
                description: "Stem cell therapy stimulates the regeneration of healthy intestinal tissue, which can improve intestinal function, reduce inflammation, and alleviate symptoms associated with intestinal diseases such as abdominal pain, diarrhea, and rectal bleeding."
            },
            {
                title: "Reduction of Inflammation",
                description: "By promoting tissue repair and modulating the immune response, stem cell treatment may help reduce inflammation in the intestine, a key driver of intestinal diseases such as Crohn's disease and ulcerative colitis."
            },
            {
                title: "Improvement in Symptoms",
                description: "Stem cell therapy can lead to improvements in symptoms associated with intestinal diseases, such as diarrhea, constipation, abdominal cramping, and fatigue, resulting in a better quality of life for patients."
            },
            {
                title: "Potential for Long-Term Benefits",
                description: "With ongoing advancements in stem cell research and technology, there is the potential for long-term benefits and sustained improvements in intestinal function and overall health following stem cell therapy for intestinal diseases."
            }
        ],
        conditionsTreated: {
            title: "Stem cell treatment for intestinal diseases can address various conditions, including:",
            rows: [
                "Crohn's disease",
                "Ulcerative colitis",
                "Irritable bowel syndrome (IBS)",
                "Diverticulitis",
                "Intestinal strictures",
                "Intestinal fistulas"
            ]
        },
        question: {
            title: "Is Stem Cell Treatment Right for You?",
            content: "If you're living with an intestinal disease and seeking alternative treatment options, stem cell therapy may offer hope for improved intestinal function, symptom relief, and quality of life. However, it's essential to consult with a qualified gastroenterologist or colorectal surgeon specializing in intestinal diseases to determine if you're a suitable candidate for treatment. Factors such as your overall health, disease severity, and treatment goals will be considered when developing a personalized treatment plan."
        }
    }
    ,
    {
        title: "CARDIOVASCULAR DISEASES",
        introduction: "Cardiovascular diseases, including heart disease and stroke, are leading causes of morbidity and mortality worldwide. While traditional treatments focus on managing risk factors and symptoms, stem cell therapy offers a promising approach to repairing damaged heart tissue and restoring cardiovascular function.",
        how: "Stem cell therapy for cardiovascular diseases involves the transplantation of healthy stem cells into the heart or bloodstream, where they can integrate into the damaged tissue and promote repair and regeneration. These specialized cells have the unique ability to differentiate into various cell types found in the heart, including cardiomyocytes, endothelial cells, and smooth muscle cells, and stimulate the production of growth factors and cytokines that support tissue healing.",
        benefits: [
            {
                title: "Heart Tissue Regeneration",
                description: "Stem cell therapy stimulates the regeneration of healthy heart tissue, which can improve heart function, reduce scar tissue formation, and alleviate symptoms associated with cardiovascular diseases such as chest pain, shortness of breath, and fatigue."
            },
            {
                title: "Angiogenesis",
                description: "By promoting the growth of new blood vessels (angiogenesis) in the heart, stem cell treatment may improve blood flow to the heart muscle, reduce ischemia, and enhance cardiac function in patients with coronary artery disease and other cardiovascular conditions."
            },
            {
                title: "Reduction of Scar Tissue",
                description: "Stem cell therapy can help reduce scar tissue formation in the heart following a heart attack or myocardial infarction, preserving cardiac function and preventing further deterioration of heart function."
            },
            {
                title: "Potential for Long-Term Benefits",
                description: "With ongoing advancements in stem cell research and technology, there is the potential for long-term benefits and sustained improvements in heart function and overall health following stem cell therapy for cardiovascular diseases."
            }
        ],
        conditionsTreated: {
            title: "Stem cell treatment for cardiovascular diseases can address various conditions, including:",
            rows: [
                "Coronary artery disease",
                "Myocardial infarction (heart attack)",
                "Heart failure",
                "Peripheral artery disease",
                "Stroke",
                "Cardiomyopathy"
            ]
        },
        question: {
            title: "Is Stem Cell Treatment Right for You?",
            content: "If you're living with a cardiovascular disease and seeking alternative treatment options, stem cell therapy may offer hope for improved heart function, symptom relief, and quality of life. However, it's essential to consult with a qualified cardiologist or cardiovascular surgeon specializing in heart diseases to determine if you're a suitable candidate for treatment. Factors such as your overall health, heart function, and treatment goals will be considered when developing a personalized treatment plan."
        }
    }
    ,
    {
        title: "CONNECTIVE TISSUE DISEASES",
        introduction: "Connective tissue diseases encompass a group of disorders that affect the body's connective tissues, including collagen, elastin, and fibrillin. These diseases can have a wide range of symptoms and complications, ranging from joint pain and stiffness to skin rashes and organ involvement. While traditional treatments aim to manage symptoms and slow disease progression, stem cell therapy offers a promising approach to addressing the underlying causes of connective tissue diseases and promoting tissue repair.",
        how: "Stem cell therapy for connective tissue diseases involves the transplantation of healthy stem cells into the body, where they can integrate into the damaged tissue and promote repair and regeneration. These specialized cells have the unique ability to differentiate into various cell types found in connective tissues, including fibroblasts, chondrocytes, and osteoblasts, and stimulate the production of growth factors and cytokines that support tissue healing.",
        benefits: [
            {
                title: "Tissue Regeneration",
                description: "Stem cell therapy stimulates the regeneration of healthy connective tissue, which can improve joint function, reduce inflammation, and alleviate symptoms associated with connective tissue diseases such as joint pain, skin rashes, and muscle weakness."
            },
            {
                title: "Reduction of Inflammation",
                description: "By promoting tissue repair and modulating the immune response, stem cell treatment may help reduce inflammation in the connective tissues, reducing pain and stiffness and improving overall function and mobility."
            },
            {
                title: "Improved Quality of Life",
                description: "Stem cell therapy can lead to improvements in symptoms and overall quality of life for individuals with connective tissue diseases, allowing them to better manage their condition and participate in daily activities."
            },
            {
                title: "Potential for Long-Term Benefits",
                description: "With ongoing advancements in stem cell research and technology, there is the potential for long-term benefits and sustained improvements in connective tissue function and overall health following stem cell therapy for connective tissue diseases."
            }
        ],
        conditionsTreated: {
            title: "Stem cell treatment for connective tissue diseases can address various conditions, including:",
            rows: [
                "Rheumatoid arthritis",
                "Systemic lupus erythematosus (SLE)",
                "Scleroderma",
                "Dermatomyositis",
                "Polymyositis",
                "Mixed connective tissue disease"
            ]
        },
        question: {
            title: "Is Stem Cell Treatment Right for You?",
            content: "If you're living with a connective tissue disease and seeking alternative treatment options, stem cell therapy may offer hope for improved tissue function, symptom relief, and quality of life. However, it's essential to consult with a qualified rheumatologist or autoimmune specialist specializing in connective tissue diseases to determine if you're a suitable candidate for treatment. Factors such as your overall health, disease severity, and treatment goals will be considered when developing a personalized treatment plan."
        }
    }
    ,
    {
        title: "POST-CANCER CARE",
        introduction: "Post-cancer care is a crucial phase in the journey of cancer survivors, focusing on recovery, rehabilitation, and long-term health management. While traditional treatments such as surgery, chemotherapy, and radiation therapy aim to eliminate cancer cells, they can also cause long-term side effects and damage to healthy tissues and organs. Stem cell therapy offers a promising approach to addressing these challenges and supporting the overall health and well-being of cancer survivors.",
        how: "Stem cell therapy for post-cancer care involves the transplantation of healthy stem cells into the body, where they can integrate into damaged tissues and organs and promote repair and regeneration. These specialized cells have the unique ability to differentiate into various cell types found in different tissues and organs, including blood cells, nerve cells, and muscle cells, and stimulate the production of growth factors and cytokines that support tissue healing.",
        benefits: [
            {
                title: "Tissue Regeneration",
                description: "Stem cell therapy stimulates the regeneration of healthy tissues and organs, which can help repair damage caused by cancer treatments and improve overall function and quality of life for cancer survivors."
            },
            {
                title: "Reduction of Side Effects",
                description: "By promoting tissue repair and modulating the immune response, stem cell treatment may help reduce the long-term side effects of cancer treatments, such as fatigue, pain, neuropathy, and organ dysfunction."
            },
            {
                title: "Improved Quality of Life",
                description: "Stem cell therapy can lead to improvements in symptoms and overall quality of life for cancer survivors, allowing them to better manage their health and participate in daily activities."
            },
            {
                title: "Support for Recovery and Rehabilitation",
                description: "Stem cell therapy can play a valuable role in supporting the recovery and rehabilitation of cancer survivors, helping them rebuild strength, endurance, and resilience following cancer treatment."
            }
        ],
        conditionsTreated: {
            title: "Stem cell treatment for post-cancer care can address various conditions and side effects associated with cancer treatments, including:",
            rows: [
                "Fatigue",
                "Pain",
                "Neuropathy",
                "Organ dysfunction",
                "Muscle weakness",
                "Cognitive impairment"
            ]
        },
        question: {
            title: "Is Stem Cell Treatment Right for You?",
            content: "If you're a cancer survivor experiencing long-term side effects or complications related to cancer treatment, stem cell therapy may offer hope for symptom relief, tissue repair, and improved quality of life. However, it's essential to consult with a qualified oncologist or cancer rehabilitation specialist to determine if you're a suitable candidate for treatment. Factors such as your overall health, cancer history, and treatment goals will be considered when developing a personalized treatment plan."
        }
    }
    ,
    {
        title: "COSMETIC SURGERY",
        introduction: "Cosmetic surgery has evolved significantly over the years, offering a wide range of procedures to enhance appearance and boost self-confidence. While traditional cosmetic surgeries focus on altering physical features through surgical intervention, stem cell therapy introduces a revolutionary approach by harnessing the regenerative power of stem cells to enhance aesthetic outcomes and promote natural-looking results.",
        how: "Stem cell therapy in cosmetic surgery involves the use of autologous (patient's own) stem cells or stem cell-enriched fat grafts to augment and rejuvenate various areas of the body. These specialized cells have the unique ability to differentiate into different cell types and promote tissue regeneration, leading to improved skin texture, volume restoration, and overall rejuvenation. Stem cells can be harvested from adipose tissue (fat), bone marrow, or other sources and then processed and injected into targeted areas to enhance facial features, fill in wrinkles and fine lines, and improve skin quality.",
        benefits: [
            {
                title: "Natural-Looking Results",
                description: "Stem cell therapy offers natural-looking results by promoting tissue regeneration and enhancing the body's natural healing processes, resulting in smoother, firmer, and more youthful-looking skin."
            },
            {
                title: "Long-Lasting Effects",
                description: "Unlike traditional fillers and implants, which may require periodic touch-ups, stem cell-enriched fat grafts can provide long-lasting improvements in volume and contour, as the stem cells promote tissue integration and long-term survival of the graft."
            },
            {
                title: "Minimal Downtime",
                description: "Stem cell procedures typically involve minimal downtime and discomfort compared to traditional surgeries, allowing patients to resume normal activities shortly after treatment with minimal disruption to their daily routines."
            },
            {
                title: "Versatility",
                description: "Stem cell therapy can be used in various cosmetic procedures, including facial rejuvenation, breast augmentation, buttock augmentation, and scar revision, offering versatility and customization to meet individual aesthetic goals."
            }
        ],
        conditionsTreated: {
            title: "Stem cell therapy in cosmetic surgery can address a range of aesthetic concerns, including:",
            rows: [
                "Wrinkles and fine lines",
                "Volume loss",
                "Sagging skin",
                "Scars and blemishes",
                "Facial asymmetry",
                "Breast and buttock augmentation"
            ]
        },
        question: {
            title: "Is Stem Cell Therapy Right for You?",
            content: "If you're considering cosmetic surgery and seeking natural-looking results with minimal downtime, stem cell therapy may be an option worth exploring. However, it's essential to consult with a qualified plastic surgeon or cosmetic dermatologist specializing in stem cell procedures to determine if you're a suitable candidate for treatment. Factors such as your overall health, aesthetic goals, and treatment expectations will be considered when developing a personalized treatment plan."
        }
    }
]

const RenderTab = ({ data }) => {
    return (
        <div className="container-fluid">
            <h3>{data.title}</h3>
            <p>{data.introduction}</p>
            <h4>How stem cell treatment work for this type of desease?</h4>
            <p>{data.how}</p>

            <strong>When injected, stem cells have the potential to: </strong>
            <ol>
                {data.benefits.map((b, index) => (
                    <li key={index}><strong>{b.title}: </strong> <br /> <p>{b.description}</p></li>
                ))}
            </ol>

            <h4>Conditions Treated: </h4>
            <strong>{data.conditionsTreated.title}</strong>
            <ul>
                {
                    data.conditionsTreated.rows.map(r => (
                        <li style={{ listStyleType: "disc" }} key={r}>{r}</li>
                    ))
                }
            </ul>

            <h4>{data.question.title}</h4>
            <p>{data.question.content}</p>
        </div>
    )
}
export default function Treatments() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [selectedRow, setSelectedRow] = useState(content[0]);
    useEffect(() => {
        if (!id) navigate("/treatments/1")
        else setSelectedRow(content[id - 1])
    }, [id])

    if (!selectedRow) return <> Loading ...</>
    return (
        <>
            <div className="mb-5" style={{ height: 180, backgroundImage: `url(/assets/images/stem-cell-header.jpg)`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}>
                <div className="container h-100">
                    <div className="d-flex h-100 align-items-center">
                        <div>
                            <h3 className="section-title" style={{ color: "white" }}>Treatments</h3>
                            <small style={{color: "white"}}>{`Home > Treatments`}</small>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container mt-3">
                <strong>
                    Using cutting-edge technology and personalized treatment plans, our team of experienced healthcare professionals is dedicated to providing comprehensive care and support to our patients. Whether you're seeking relief from a specific health issue or looking to enhance your natural beauty, we're committed to helping you achieve your health and wellness goals.
                </strong>
            </div>

            <div className="container py-4">


                <Tabs
                    onTabClick={(index) => {
                        navigate("/treatments/" + (index + 1));
                        window.scrollTo(0, 0)
                    }
                    }
                    activeKey={id-1}
                    tabPosition={window.innerWidth < 1000 ? "top" : "left"}
                    items={content.map((c, index) => {
                        return {
                            label: c.title,
                            key: index,
                            children: <RenderTab data={c} />
                        }
                    })}
                />
            </div>
        </>
    )
}