import axios from "axios";
import { serverData } from "../utils/form.utils";

// Base URL for the API
const API_URL = process.env.API_URL || "http://localhost:3005";

// Axios instance for public endpoints (without Authorization header)
export const publicApi = axios.create({
  baseURL: API_URL,
});

// Axios instance for protected endpoints (with Authorization header)
export const protectedApi = axios.create({
  baseURL: API_URL,
});

// Set up a request interceptor for protectedApi to add the Authorization header
protectedApi.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken"); // Get the access token from local storage

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`; // Add the Authorization header
    }

    if (config.method === "put") {
      config.data = serverData(config.data);
    }

    return config; // Return the modified config
  },
  (error) => Promise.reject(error) // Handle errors
);

// Set up a request interceptor for publicApi to add modife_le
publicApi.interceptors.request.use(
  (config) => {
    if (config.method === "put") {
      config.data = serverData(config.data);
    }

    return config; // Return the modified config
  },
  (error) => Promise.reject(error) // Handle errors
);


export { API_URL };
